import "./App.css";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import React from "react";
import Bottom from "./functions/Bottom.js";
function Visual(props) {
  return (
    <div>
      <div className="bodytext">
        {/* <ProjHead
          title="NYTimes CMS and Live Video Resizing "
          description="Workflow Improvements and Prototyping Micro-interactions"
          image="./nyt/nytcover.png"
          timeline=" Summer 2022, 8 weeks"
          role="With"
          team="advising from 1 senior designer, 1 PM"
          tools="Figma"
          context="Internship Project"
        /> */}
        <div className="projmargins">
          <h2 id="Coming Soon">Overview</h2>
          <p>
            This page is under construction! Give me a day (9/8) to update :^D
          </p>
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Visual;
