import "./App.css";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import React from "react";
import Bottom from "./functions/Bottom.js";
function NYTimes(props) {
  return (
    <div>
      <div className="bodytext">
        <ProjHead
          title="NYTimes CMS and Live Video Resizing "
          description="Workflow Improvements and Prototyping Micro-interactions"
          image="./nyt/nytcover.png"
          timeline=" Summer 2022, 8 weeks"
          role="With"
          team="advising from 1 senior designer, 1 PM, engineering lead"
          tools="Figma"
          context="Internship Project"
        />
        <div className="projmargins">
          <h2 id="Overview">Overview</h2>
          <p>
            At this time my work is under <strong>NDA</strong> as it falls under
            internal tools or has not been released yet. Over my time at NYT:
          </p>
          <ul>
            <li>
              Shipped "Hold for Order" (work for a specific event that will
              happen in the future) label integration to the Live Admin CMS
              (Content Management System).
            </li>
            <li>
              Shipped four workflow improvements to Pilot to ensure bursts
              (example below) are published in a readable manner utilizing NYT's
              Ink design system.
            </li>
          </ul>
          <img src="nyt/burst.png" alt="burst example" />{" "}
          <ul>
            <li>
              Prototyped MVP of live video minimization micro-interaction with
              feasibility input from engineers and management.
            </li>
            <li>
              Proposed PM - designer organizational communication improvements
              to give designers more product agency{" "}
            </li>
            <li>
              Worked on 4 maker week projects (week long hackathon), won best UI
              for octopus illustrations for Kids App.
            </li>
          </ul>
          <h2 id="Constraints">Constraints and Learnings</h2>
          <ol>
            <li>
              <strong>A low-bandwidth PM who was managing four teams</strong> -
              I navigated around this by asking other team members on analogous
              CMS products about their design process to reduce the number of
              iterations. Also I juggled two projects so I could work on one
              while waiting for the PM to answer my questions async.
            </li>
            <li>
              <strong>Restricted user interaction</strong> - Because our users
              were busy journalists, it was difficult to get feedback even for
              an internal tool. I relied on documentation from prior research
              sprints to get up to date with user problems and prepared for
              weekly 10 minute calls with product onboarding liasons who served
              as a proxy.
            </li>
            <li>
              <strong>Lack of documentation</strong> - To better understand the
              design workflow, I asked designers what got most stakeholder
              buyin. Due to the team's low engineering bandwidth I learned{" "}
              <em>feasibility was emphasized</em>, I used this as a north star
              in proposing my ideas which were eventually sent to production.
            </li>
          </ol>
          <p>
            The summer was a great learning experience in understanding how to
            navigate a resource-constrained environment and for advocating my
            value as a designer. I look forward to my next adventures in design!
          </p>
          <img src="nyt/thanks.png" alt="thankyounote" />{" "}
        </div>
        <Bottom nextproject="Hack4Impact Design / Operations" next="/CANY" />
        <Footer />
      </div>
    </div>
  );
}
export default NYTimes;
