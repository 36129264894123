import React from "react";
import "../App.css";

function Footer(props) {
  return (
    <div className="footer">
      <p>
        <a href="https://instagram.com/conliuart">instagram </a>✿
        <a href="https://www.linkedin.com/in/connie-liu-617b8b148/">
          {" "}
          linkedin{" "}
        </a>
        ✿<a href="https://www.are.na/connie-liu-kg4ypfp7ry0"> aren.a </a>
      </p>
      <div className="nochange">
        <p>
          🍞 this site was kneaded from scratch with react.js 🍞{" "}
          <a href="https://github.com/connieliu0/connieliu0.github.io/blob/ef92012344fc9c0e0a278b5206c66294231d262f/README.md">
            here
          </a>{" "}
          are the sources that helped me
        </p>
      </div>
      <p id="gray">
        values: earnestness, optimism, kindness and questioning the status quo
      </p>
    </div>
  );
}

export default Footer;
