import React from "react";
import { HashRouter, Route, Link } from "react-router-dom";
import Design from "./Design.js";
import NIH from "./NIH.js";
import Art from "./art/Art.js";
import About from "./About.js";
import abillion from "./Abillionveg.js";
import Arcade from "./Arcade.js";
import Twitter from "./Twitter.js";
import Buzzfeed from "./Buzzfeed.js";
import Scale from "./Scale.js";
import showcase from "./GSoC.js";
import cany from "./CANY.js";
import Fun from "./fun/Fun.js";
import NYTimes from "./nyt.js";
import Habitat from "./Habitat.js";
import Visual from "./Visual.js";
import ScrolltoTop from "./functions/ScrolltoTop.js";
import "./App.css";
import Analytics from "react-router-ga";
import { HashLink } from "react-router-hash-link";

function App() {
  return (
    <HashRouter>
      <Analytics id="UA-175461618-1" debug>
        <div className="App">
          <div className="header">
            <div className="navigation">
              <h2>
                <Link to="/">Connie Liu</Link>
              </h2>
              <div className="navigation-sub">
                <HashLink to="/#DesignProjs" className="item">
                  Design
                </HashLink>
                <Link to="/researchcode" className="item">
                  Research+Code
                </Link>
                <Link to="/art" className="item">
                  Art
                </Link>
                <Link to="/about" className="item">
                  About
                </Link>
                <a
                  class="item"
                  href="https://drive.google.com/file/d/1dpLJniPJ_r5R-_LGebnnKQO3wzdQdFyM/view?usp=sharing"
                  target="_blank"
                >
                  Resume
                </a>
              </div>
            </div>
            <Route exact path="/" component={Design} />
            <Route path="/art" component={Art} />
            <Route path="/researchcode" component={Fun} />
            <Route path="/about" component={About} />
          </div>
          <ScrolltoTop />
          <Route path="/scale" component={Scale} />
          <Route path="/habitat" component={Habitat} />
          <Route path="/buzzfeed" component={Buzzfeed} />
          <Route path="/NIH" component={NIH} />
          <Route path="/arcade" component={Arcade} />
          <Route path="/twitter" component={Twitter} />
          <Route path="/abillion" component={abillion} />
          <Route path="/p5showcase" component={showcase} />
          <Route path="/CANY" component={cany} />
          <Route path="/nyt" component={NYTimes} />
          <Route path="/visual" component={Visual} />
        </div>
      </Analytics>
    </HashRouter>
  );
}

export default App;
