import React from 'react';
import {Link } from 'react-router-dom';
import '../App.css';
function Bottom(props) {
  return (
    <div className="projfooter">
     <div className="left">
    <Link to="/">
     <h2>← Back</h2>
     <h3>View All Projects</h3>
     </Link>
     </div>
     <div className="right">
       {props.next.substring(0,4)==="http"?<a href={props.next} target="_blank">
       <h2>Next Project →</h2>
    <h3>{props.nextproject}</h3>
    </a>   :
     <Link to={props.next}>
    {(props.nextproject.length!==0)&&<h2>Next Project →</h2>}
    <h3>{props.nextproject}</h3>
    </Link>
}
     </div>
    </div>
  )
}
export default Bottom;