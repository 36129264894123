import "./App.css";
import Bottom from "./functions/Bottom.js";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import React from "react";
import ReactPlayer from "react-player";
import Zoom from "./functions/Zoom.js";
import Scrollspy from "react-scrollspy";
import { HashLink } from "react-router-hash-link";

function NIH(props) {
  return (
    <div>
      <div className="bodytext">
        {/* <hr /> */}
        <ProjHead
          title="Civic Digital Fellowship with the NIH NIBIB"
          description="Creating a scalable education cection for the NIBIB IMAG Wiki"
          image="./NIH/NIHcover.png"
          context="Civic Digital Fellowship Fall Internship"
          timeline="October-December 2020, 7 weeks"
          role="With"
          team="Grace Zhou-Designer (worked on adjacent project), Grace Peng-Supervisor"
          tools="Figma"
        />
        <div className="sticky">
          <nav className="section-nav">
            <Scrollspy
              items={[
                "solution",
                "problem",
                "research",
                "system",
                "iterations",
                "results",
              ]}
              currentClassName="is-current"
            >
              <li>
                <HashLink to="#solution">Solution Overview</HashLink>
              </li>
              <li>
                <HashLink to="#problem">Problem</HashLink>
              </li>
              <li>
                <HashLink to="#research">User Research</HashLink>
              </li>
              <li>
                <HashLink to="#system">Design System</HashLink>
              </li>
              <li>
                <HashLink to="#iterations">Design Iterations</HashLink>
              </li>
              <li>
                <HashLink to="#results">Results</HashLink>
              </li>
            </Scrollspy>
          </nav>
        </div>

        <div className="projmargins">
          <h2>Overview</h2>
          <p>
            For the Fall of 2020 I was selected to be part of the 37 person{" "}
            <a href="https://www.codingitforward.com/civic-digital-fellowship">
              Civic Digital Fellowship
            </a>{" "}
            cohort (10% acceptance rate). The fellowship is a paid internship
            for student technologists to solve pressing problems in federal
            agencies and I was able to also learn more about civic tech through
            various speaker events and mentorship circles. Beyond my design
            project I worked with the NLM's budget process - you can read more
            about that project{" "}
            <a href="https://github.com/codingitforward/cdfdemodayfall2020/blob/main/Connie_Liu.pdf">
              here
            </a>
            .
          </p>
          <p>
            For my project, I was tasked with creating an education section
            geared towards the public for the National Institute of Biomedical
            Imaging and Bioengineering's
            <a href="https://www.imagwiki.nibib.nih.gov/">
              {" "}
              Interagency Modeling and Analysis Group Wiki
            </a>
            . The IMAG Wiki is currently used by modeling researchers (graduate
            and doctoral students) as it is a central resource for professionals
            to share their research and record notes on the organization's
            meetings.
          </p>
          <p>
            Although current resources are highly geared towards specialists,
            the IMAG Wiki would be the optimal place for a public education
            section as there is already a pre-existing community of specialists
            that could contribute lessons and materials.
          </p>
          <section id="solution">
            <h2 id="solution">Solution</h2>
            <p>
              Over the seven weeks, I prototyped an education section for the
              IMAG Wiki website. Major features include Tutorials, Projects,
              Stories (a news section), Contribute, Resources and FAQ.
            </p>
            <p>Watch the video below for a walkthrough!</p>
            <ReactPlayer url="https://youtu.be/FxqAu8Cyabw" />
          </section>

          <section id="problem">
            <h2 id="discovering-the-problem">Discovering the Problem</h2>
            <h3 id="problem">Problem</h3>
            <p>
              Currently, there is no centralized platform to learn modeling, and
              current websites such as NetLogo and IMAG Wiki's Resources section
              are not optimal for beginners, which encompasses most members of
              the public.
            </p>
            <img src="NIH/Untitled.png" alt="feasibility" />
            <h3 id="which-member-of-the-public">Which member of the public?</h3>
            <p>
              Under the IMAG Wiki's Public Dissemination Working Group, they had
              defined the public to host a range of categories from students to
              retirees. I did preliminary members with one member from each
              group to figure out which user group to focus on.
            </p>
            <img src="NIH/Untitled%201.png" alt="NIH/Untitled%201.png" />
            <p>
              I then mapped each user onto an impact-feasibility matrix, and
              found that it would be best to serve Middle/High Schoolers.
            </p>
            <img src="NIH/Untitled%202.png" alt="NIH/Untitled%202.png" />
            <p>
              Middle/High Schoolers would benefit the most from Public Education
              because:
            </p>
            <ul>
              <li>
                High impact: Early education increases diversity in the field
              </li>
              <li>
                High feasibility: Many tools exist that K-12 could use (eg
                Netlogo and Scratch) but no aggregated site for all pre-existing
                resources
              </li>
            </ul>
          </section>

          <section id="research">
            <h4>User Research</h4>
            <h2 className="none" id="user-research">
              Understanding Online Education Systems
            </h2>
            <p>
              I interviewed four different groups of users - Middle/High School
              teachers (blue), Professors of modeling (yellow),
              Undergraduates/Graduates who teach (pink), and Middle/High School
              students (green). In total I interviewed 12 users.
            </p>
            <p>I aimed on learning:</p>
            <ul>
              <li>
                Weaknesses and strengths of current online learning solutions
              </li>
              <li>
                What aspects of modeling would be achievable to translate online
              </li>
              <li>What motivate users to learn and contribute</li>
            </ul>
            <div class="figure">
              <img src="NIH/Untitled%203.png" alt="NIH/Untitled%203.png" />
            </div>
            <div class="figure">
              <Zoom src="NIH/Untitled%204.png" alt="NIH/Untitled%204.png" />
            </div>
            <h2 id="key-takeaways">Key Takeaways</h2>
            <p>
              ✍️<strong>Learning</strong>
            </p>
            <ul>
              <li>
                Users want to learn modeling if it is{" "}
                <strong>applicable to their lives</strong>
              </li>
              <li>
                Effective models are ones that are{" "}
                <strong>interactive and can be remixed.</strong>
              </li>
              <li>
                <strong>Interactive</strong> materials like videos and podcasts
                are most helpful
              </li>
              <li>
                There should be{" "}
                <strong>flexibility in skill progression</strong>
              </li>
            </ul>
            <p>
              👐<strong>Inclusion</strong>
            </p>
            <ul>
              <li>
                Users want to see <strong>representation</strong>
              </li>
              <li>
                There should be materials for{" "}
                <strong>different skill levels</strong>
              </li>
            </ul>
            <p>
              🏆<strong>Incentives</strong>
            </p>
            <ul>
              <li>
                <strong>Competitions</strong> would incentivize people to use
                and contribute
              </li>
            </ul>
          </section>

          <section id="system">
            <h4>Design Systems</h4>
            <h2 id="design-system" className="none">
              Creating Consistency in Design
            </h2>
            <p>
              While my co-designer was not involved in the creation of Education
              wireframes, she was in charge of redesigning the IMAG Wiki as a
              whole to improve usability and we designed the design system
              together. Because the IMAG Wiki is a federally funded group we
              based it off the US Web Design system to ensure it followed
              federal accessibility and design standards.
            </p>
            <div class="figure">
              <Zoom src="NIH/Untitled%205.png" alt="design system" />
            </div>
            <p>
              I personally took inspiration for the Education section from the
              NIEHS' education website.
            </p>
            <div class="figure">
              <img src="NIH/Untitled%206.png" alt="NIH/Untitled%206.png" />
            </div>
          </section>

          <section id="iterations">
            <h2 id="design-iterations">Design Iterations</h2>
            <p>
              I iterated on various parts of my design through two rounds of
              user testing with 8 users each, all with varying backgrounds. I
              made many small changes, some content related and some usability
              related. Below are some major pivotal decisions.
            </p>
            <h3 id="decision-1-create-tutorials-to-be-video-based">
              Decision 1: Create tutorials to be video-based
            </h3>
            <p>
              Previously I had two sections that both featured step by step
              walkthroughs with one being shorter than the other.
            </p>
            <div class="figure">
              <Zoom src="NIH/Untitled%207.png" alt="NIH/Untitled%207.png" />
            </div>
            <h3 id="decision-2-add-more-variation-to-the-stories-layout">
              Decision 2: Add more variation to the Stories Layout
            </h3>
            <p>
              I went through many visual design iterations to the stories page.
            </p>
            <div class="figure">
              <Zoom src="NIH/Untitled%208.png" alt="NIH/Untitled%208.png" />
            </div>
            <h3 id="decision-3-change-home-page-from-carousel-to-multiple-components">
              Decision 3: Change home page from Carousel to Multiple Components
            </h3>
            <div class="figure">
              <Zoom src="NIH/Untitled%209.png" alt="NIH/Untitled%209.png" />
            </div>
            <h3 id="decision-4-contribution-form-not-available-through-direct-links">
              Decision 4: Contribution Form Not Available through Direct Links
            </h3>
            <div class="figure">
              <Zoom src="NIH/Untitled%2010.png" alt="NIH/Untitled%2010.png" />
            </div>
            <h3 id="decision-5-separate-about-into-different-sections">
              Decision 5: Separate About into Different Sections
            </h3>
            <div class="figure">
              <Zoom src="NIH/Untitled%2011.png" alt="NIH/Untitled%2011.png" />
            </div>
          </section>

          <section id="results">
            <h2 id="final-design">Final Design</h2>
            <p>
              <strong>
                Easy, clear navigation that allows customizable experiences
                based off of experience
              </strong>
            </p>
            <div class="figure">
              <Zoom src="NIH/Untitled%2012.png" alt="NIH/Untitled%2012.png" />
            </div>
            <p>
              <strong>
                Distinct learning experiences on how to use software, develop
                modeling intuition, and modeling as a career
              </strong>
            </p>
            <div class="figure">
              <Zoom src="NIH/Untitled%2013.png" alt="NIH/Untitled%2013.png" />
            </div>
            <p>
              <strong>
                Actionable ways to expand on resources and to contribute
              </strong>
            </p>
            <div class="figure">
              <Zoom src="NIH/Untitled%2014.png" alt="NIH/Untitled%2014.png" />
            </div>
            <h2 id="results">Results</h2>
            <p>
              I presented to the Multi-scaled Modeling committee who also
              oversees the IMAG Wiki and was met with majorly positive reviews.
              I also sent out a followup survey to all the users I tested to see
              their opinion of the final design and majority said it was{" "}
              <strong>effective in accomplishing its goals</strong>.
            </p>
            <p>
              The biggest question was-
              <strong>
                who was going to implement the content copy of the website
              </strong>
              ?Because it takes a long amount of time to write up a tutorial and
              have others proofread it, I do not have an estimation of when it
              will be deployed. Because of this, I made sure to have thorough
              documentation for both developers and contributors so the project
              could continue to be built after I left the NIH.
            </p>
            <h3 id="reflections">Reflections</h3>
            <ul>
              <li>
                <strong>Pivot when necessary -</strong> My original project was
                to make some sort of financial planner tool for the NLM until
                user research revealed the most efficient solution was better
                documentation. With the remaining seven weeks I was able to
                pivot to a design project.
              </li>
              <li>
                <strong>Consistency + Flexibility</strong> - I learned how to
                work with a pre-existing design system, but also to make
                original assets when needed that lined up with it.
              </li>
              <li>
                <strong>Talk out ideas -</strong> Every day I had check in
                meetings with my codesigner and supervisor and I made sure to
                have a list of topics to go over. Making decisions with others
                helped me cover edge cases I may have forgotten.
              </li>
            </ul>
            <h3 id="bonus">Bonus</h3>
            <div className="row">
              <div className="column">
                <img src="NIH/Untitled%2015.png" alt="NIH/Untitled%2015.png" />
              </div>
              <div className="column">
                <p>
                  In addition to my design work, I got the opportunity to design
                  flyers for the NLM's Medline Plus guide on how to find
                  accurate and reputable health information online!
                </p>
              </div>
            </div>
            <p>
              All in all, the best part of the fellowship was meeting cool
              people interested in civic technology and social good, which is
              something I'll forever remember.
            </p>
            <img src="NIH/Untitled%2016.png" alt="NIH/Untitled%2016.png" />
          </section>
        </div>
        <Bottom nextproject="Research and Code" next="/researchcode" />
      </div>
      <Footer />
    </div>
  );
}
export default NIH;
