import React from "react";
import Footer from "../functions/Footer.js";
import "../App.css";
import Gallery from "react-photo-gallery";

function Art(props) {
  const photos = [
    { src: "dig/hill.png", width: 2526, height: 2048 },
    { src: "trad/look.png", width: 974, height: 1220 },
    { src: "trad/forgetmenot.png", width: 1008, height: 998 },
    { src: "comic/cover.jpg", width: 700, height: 994 },
    { src: "comic/p1.jpg", width: 700, height: 994 },
    { src: "comic/p2.jpg", width: 700, height: 994 },
    { src: "comic/p3.jpg", width: 700, height: 994 },
    { src: "comic/p4.jpg", width: 700, height: 994 },
    { src: "comic/p5.jpg", width: 700, height: 994 },
    { src: "comic/p6.jpg", width: 700, height: 994 },
    { src: "trad/Vegetation.jpg", width: 1000, height: 764 },
    { src: "dig/life.jpg", width: 800, height: 1200 },
    { src: "dig/rain.jpg", width: 2500, height: 2500 },
    { src: "dig/whatgoes.jpg", width: 1000, height: 591 },
  ];
  return (
    <div>
      <div className="row" id="rnc">
        <div className="column2">
          <h2>Art Projects (2018 - Present)</h2>
        </div>
        <div className="column2">
          <p>
            In my free time I love illustrations and non-linear narratives -
            feel free to email me for any potential collaborations!
          </p>
        </div>
      </div>
      <Gallery photos={photos} />
      <Footer />
    </div>
  );
}

export default Art;
