import React from "react";
import Thumbnail from "./Thumbnail.js";
import Footer from "./functions/Footer.js";
import { Link } from "react-router-dom";
import "./App.css";
import "./home.css";
import HalfThumb from "./Halfthumb.js";

function Design(props) {
  return (
    <div>
      <div className="titledesc">
        <div className="title">
          <h3>
            Connie is a cross-functional technologist continuously iterating ✿
          </h3>
        </div>
        <div className="profile">
          <img src="profile.png" img alt="profile" />
        </div>
        <div className="smalltitle">
          <p>
            currently <a href="https://scale.com/">Scale AI</a>, prev.{" "}
            <a href="https://fellows.kleinerperkins.com/" target="blank">
              Kleiner Perkins Fellows
            </a>
            ,{" "}
            <a href="https://www.nytimes.com" target="blank">
              NYTimes
            </a>
            ,{" "}
            <a href="https://www.buzzfeed.com/" target="_blank">
              BuzzFeed
            </a>
            , the
            <a
              href="https://www.codingitforward.com/summer-fellowships"
              target="blank"
            >
              {" "}
              Civic Digital Fellowship
            </a>
            , led{" "}
            <a href="https://www.cornellh4i.org/" target="blank">
              Cornell Hack4Impact
            </a>
            , and developed <a href="">Google Summer of Code</a>{" "}
          </p>
        </div>
      </div>
      {/* <hr /> */}
      <div className="row" id="DesignProjs">
        <HalfThumb
          link="/scale"
          date="Fall 2022"
          type="Product Design Internship"
          image="images/scale.png"
          title="Scale AI Data"
          description="Onboarding + quality monitoring improvements for ML products "
        />
        <HalfThumb
          link="/buzzfeed"
          date="Summer 2021"
          type="Product Design Internship"
          image="images/buzz.png"
          title="BuzzFeed Pubhub"
          description="Creating an editor for automating social media"
        />
        <HalfThumb
          link="/twitter"
          image="images/twitter.png"
          title="Twitter Groups"
          date="Spring 2020"
          type="Independent Case Study"
          description="Customizing and curating social spaces on Twitter"
        />
        <HalfThumb
          link="/nyt"
          date="Summer 2022"
          type="Product Design Internship (Designs under NDA)"
          image="images/nyt.png"
          title="NYTimes CMS"
          description="Workflow improvements to CMS and live video"
        />
        <HalfThumb
          link="/CANY"
          image="images/CANY.png"
          title="Hack4Impact"
          date="2020-Present"
          type="Design/Leadership"
          description="Building intentional social impact tools and a community"
        />
        <HalfThumb
          link="no"
          web="https://www.notion.so/connieliu/RDV-SHK-WIP-53304f4efcc9492c9df97e2a762299f4"
          image="images/SHK.png"
          title="RDV/SHK"
          type="Hackathon/Contract Work"
          description="0-1 Design + Visuals "
        />{" "}
      </div>
      <div className="row">
        <section>
          <div id="rnc">
            <div className="row" id="rnc">
              <div className="column2" id="special">
                <h2>Take a quick peek at even more work... ⚘</h2>
              </div>
              <div className="column2">
                <p>
                  I like to keep a collection of previous product and design
                  work I've done, I find I learn the best by doing! ~ check out
                  even earlier works (hackathons etc.){" "}
                  <a
                    href="https://connieliu.notion.site/Running-Archive-1f398a5de0624456bede710ff18eb74d"
                    target="_blank"
                  >
                    here
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <Thumbnail
          web="https://connieliu.notion.site/Encouraging-Figma-Use-for-Novices-through-Templates-and-Onboarding-ef4e6778175c49fa99595605bf1d1bb8"
          link="no"
          image="images/figma.png"
          title="Figma Templates Strategy"
          description="KP 2022 Design Challenge"
        />
        <Thumbnail
          link="/abillion"
          image="images/abillionveg.png"
          title="abillion Communities"
          description="Product Internship"
        />
        <Thumbnail
          link="/NIH"
          image="images/NIH.png"
          title="National Institutes of Health"
          description="User Experience Design Fellowship"
        />
      </div>

      <div className="right" id="rnc">
        <Link to="/researchcode">
          <h3>Interested in more? View Work in Research & Code →</h3>
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Design;
