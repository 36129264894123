import React from 'react'
import mediumZoom from 'medium-zoom'
 
function ImageZoom({ zoom, src, alt, background }) {
  const zoomRef = React.useRef(zoom.clone({ background }))
 
  function attachZoom(image) {
    zoomRef.current.attach(image)
  }
 
  return <img src={src} alt={alt} ref={attachZoom} />
}
 
function Zoom(props) {
  const zoom = React.useRef(mediumZoom({ background: '#020202', margin: 48 }))
 
  
    return (
      <ImageZoom src= {props.src} alt={props.alt} zoom={zoom.current} background='#2B2B2B' />
    )
  
}
export default Zoom;