import React from "react";
// import RCThumb from '../RCThumb.js';
import ThumbnailCode from "../ThumbnailCode.js";
import Thumbnail from "../Thumbnail.js";
import Footer from "../functions/Footer.js";
import { Link } from "react-router-dom";
import "../App.css";
import "../home.css";

function Code(props) {
  return (
    <div>
      <div className="bodytextwide">
        <div className="titledesc"></div>
        {/* <hr/> */}
        <div className="row" id="rnc">
          <div className="column2">
            <h2>Research</h2>
            <p>
              I enjoy ideating on new futures through HCI research on how design
              intersects with AI, creativity tools, and social good
              applications!
            </p>
            <p>
              <a href="https://dl.acm.org/doi/abs/10.1145/3530190.3534832">
                Improving the Gender Digital Divide in ICT: A Closer Look at
                Ghana, South Africa, and India →
                <br />
                <em>Fall 2021, Published in ACM COMPASS '22</em>
              </a>
            </p>
          </div>
          <div className="column2">
            <p>
              <a href="https://github.com/connieliu0/textmining-final/blob/main/project.ipynb">
                Student Mental Health Analysis w/ Reddit Data→
                <br />
                <em>Spring 2022, Text Mining Final Project</em>
              </a>
            </p>
            <p>
              <a href="https://drive.google.com/file/d/11kyZiwPxJoDplAi2A3KzzhicOBl1cZw3/view?usp=sharing">
                Encouraging Positive Social Media Use →<br />
                <em>Spring 2021, Qualitative User Research Methods</em>
              </a>
            </p>
            <p>
              <a href="https://docs.google.com/presentation/d/11a2WExSXPwF-NuMU9JBJEN88Q5S_nuEuFhAWdPL4-qA/edit?usp=sharing">
                Speculative Design to Help Pregnant Women →<br />
                <em>Spring 2021, Designing AI Lab</em>
              </a>
            </p>
            <p>
              <a href="https://www.notion.so/Fall-2020-Research-Designing-AI-Lab-65443423237046b4ac311ecd19390154">
                Exploration of Generative Poetry Systems →<br />
                <em>Fall 2020, Designing AI Lab</em>
              </a>
            </p>
          </div>
        </div>
        <div className="row" id="rnc">
          <div className="column2">
            <h2>Code</h2>
            <p>
              I love coding new interactive experiences to creatively explore
              both a visual and data-driven virtual space!{" "}
            </p>
          </div>
          <div className="column2"></div>
        </div>
        <div className="row">
          <ThumbnailCode
            title="Hate Crimes Data Viz"
            image="images/dataviz.png"
            live="https://connie-liu.me/info3300_project2/"
            code="https://github.com/graciejing/info3300_project2"
            description="Spring 2021, d3.js"
            link="no"
          />
          <Thumbnail
            title="p5.js Showcase"
            image="images/gsoc.png"
            description="Google Summer of Code 2020, React"
            link="/p5showcase"
          />
          <Thumbnail
            title="SF Tree Data Viz"
            image="images/tree.png"
            description="Spring 2022, d3.js"
            link="no"
            web="https://cl2264-info4310-hw1.herokuapp.com/static/"
          />
          <Thumbnail
            title="Wordle Data Viz"
            image="images/wordle.png"
            description="Spring 2022, d3.js"
            link="no"
            web="https://oz28-cl2264-info4310-hw2.herokuapp.com/"
          />
          <Thumbnail
            title="New York Taxi Data Viz"
            image="images/taxi.png"
            description="Spring 2022, d3.js"
            link="no"
            web="https://info4310-nyc-transport.herokuapp.com/"
          />
          <ThumbnailCode
            title="Design Online Showcase"
            image="images/medium.png"
            live="https://connie-liu.me/raw2020/"
            code="https://github.com/judyhuang8/raw2020"
            description="Spring 2020, HTML/CSS/JS"
            link="no"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Code;
