import "./App.css";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import React from "react";
import Bottom from "./functions/Bottom.js";
function Scale(props) {
  return (
    <div>
      <div className="bodytext">
        <ProjHead
          title="Scale AI Data Annotation (Rapid and Studio) "
          description="Making it easier to onboard onto highly technical products and monitor data labeling results for quality"
          image="./images/scalecover.png"
          timeline=" Fall 2022, 12 weeks"
          role="With"
          team="1 designer, 1 PM, 2 devs"
          tools="Figma"
          context="Internship Projects"
        />
        <div className="projmargins">
          <h2 id="Coming Soon">Overview</h2>
          <p>
            Scale AI is a startup worth $7B and recently raised a 350 million
            series E. The company focuses on quick innovation in the ML
            infrastucture space. I was lucky enough to work on Rapid and later
            Studio (data labeling quality platforms), shipping 3 projects after
            week 6 while also contributing to the design system.
          </p>
          <img src="scale/examples1.png" img alt="scale example" />
          <img src="scale/examples2.png" img alt="scale example" />
          <p>
            Although my work isn't publicly available for viewing,{" "}
            <strong>please email me (cl2264@cornell.edu)</strong> for more
            details about my process. I'm lucky and grateful to grow in a space
            that focuses on thoughtful and precise execution (I literally think
            in autolayout now).
          </p>
          <p>
            If you're interested in a <strong>publicly available case</strong> -
            view the next project in my portfolio!
          </p>
          <Bottom nextproject="BuzzFeed PubHub" next="/buzzFeed" />
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Scale;
