import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
function HalfThumb(props) {
  const link1 = props.link;
  if (link1 == "no") {
    return (
      <div className="halfproject">
        <a href={props.web} target="_blank">
          <div className="row">
            <div className="project-image">
              <img src={props.image} alt="Project Image" />
            </div>
            <div className="col">
              <div className="project-title">{props.title}</div>
              <div className="year">
                <em>{props.type}</em>
              </div>
              <div className="project-description">{props.description}</div>
            </div>
          </div>
        </a>
      </div>
    );
  } else {
    return (
      <div className="halfproject">
        <Link to={props.link}>
          <div className="row">
            <div className="project-image">
              <img src={props.image} alt="Project Image" />
            </div>
            <div className="col">
              <div className="project-title">{props.title}</div>
              <div className="year">
                <em>{props.type}</em>
              </div>
              <div className="project-description">{props.description}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
export default HalfThumb;
