import "./App.css";
import Bottom from "./functions/Bottom.js";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import Zoom from "./functions/Zoom.js";
import React from "react";
import Scrollspy from "react-scrollspy";
import { HashLink } from "react-router-hash-link";
function abillion(props) {
  return (
    <div>
      <div className="bodytext">
        {/* <hr /> */}
        <ProjHead
          title="abillion Communities"
          description="Prototyping a feature to make stronger bonds around sustainable consumption"
          image="abillion/abillionvegcover.png"
          timeline="Summer 2020 8 weeks"
          role="With"
          team="Product Manager, Advising from Design Team (2)"
          context="Part-time Internship"
          tools="Adobe XD, Figma"
        />
        <div className="sticky">
          <nav className="section-nav">
            <Scrollspy
              items={[
                "problemstatement",
                "solution",
                "userresearch",
                "brainstorming",
                "infoarch",
                "designdecisions",
                "conclusion",
              ]}
              currentClassName="is-current"
            >
              <li>
                <HashLink to="#problemstatement">Problem Statement</HashLink>
              </li>
              <li>
                <HashLink to="#solution">Solution Overview</HashLink>
              </li>
              <li>
                <HashLink to="#userresearch">User Research</HashLink>
              </li>
              <li>
                <HashLink to="#brainstorming">Brainstorming</HashLink>
              </li>
              <li>
                <HashLink to="#infoarch">Info Architecture</HashLink>
              </li>
              <li>
                <HashLink to="#designdecisions">Desigh Decisions</HashLink>
              </li>
              <li>
                <HashLink to="#conclusion">Conclusion</HashLink>
              </li>
            </Scrollspy>
          </nav>
        </div>
        <div className="projmargins">
          <h2 id="context">What is abillion?</h2>
          <p>
            <a href="https://www.crunchbase.com/organization/abillionveg">
              abillion
            </a>{" "}
            is a startup based in Singapore focused on crowdsourcing and
            aggregating reviews for various vegan products from restaurant
            dishes to hygiene products and serves 240,000 users internationally
            who are mostly vegans and vegetarians.
          </p>
          <section id="problemstatement">
            <h2 id="problem">Problem</h2>
            <p>
              A major part of abillion's platform is that a dollar is donated to
              social impact causes for every review written. However this leads
              to a problem where most users use abillion to look at and create
              reviews, and there is not much <strong>user to user</strong>{" "}
              interaction.
            </p>
            <h3>User to User Interaction in Numbers</h3>
            <p>
              Through analytics we noticed users with many followers (400+), get
              on average less than 100 likes, and on average 2% of users comment
              on reviews. At the time, commenting was the only{" "}
              <strong>user to user</strong> interaction available. Part of
              abillion's business goals is to foster a community, but the
              limited scope of the app is making it difficult to do so.
            </p>
            <h3 id="the-problem-statement-">Hypothesis</h3>
            <p>
              People find it difficult to engage w/ others (via commenting,
              checking feed, liking, sharing) on abillion because:
            </p>
            <div className="row">
              <div className="col">
                <p>
                  <span>❤️</span>
                  <br />
                  They might not have any points of connection with others such
                  as common location, part in their vegan journey, etc
                </p>
              </div>
              <div className="col">
                <p>
                  <span>💬</span>
                  <br />
                  There are no other means of communicating besides commenting
                  on reviews, which limits the <strong>contexts</strong> people
                  could reach out through
                </p>
              </div>
            </div>
            <h3>
              <em>
                How do we increase user engagement on abillion beyond posting
                reviews?
              </em>
            </h3>
          </section>
          <section id="solution">
            <h4>Solution: Direct-Messaging and Community Features</h4>
            <h2 className="none">Creating New Opportunities for Connection</h2>
            <p>
              I designed <strong>two features</strong> to increase user
              interaction under the following <strong>constraints:</strong>
            </p>
            <ul>
              <li>
                Part of the solution had to include direct messaging as the
                Product Manager determined it was necessary
              </li>
              <li>
                I could not go to high-fidelity prototyping as the design system
                was still changing, but I built as closely to it as possible.
                For this portfolio case study I ended up mocking up in
                high-fidelity anyways for personal purposes, as a result it
                strays from the design system.
              </li>
            </ul>
            <div className="opening">
              <div className="row">
                <div className="col">
                  <img src="abillion/directmessage.gif" alt="direct message" />
                </div>
                <div className="col">
                  <h3 id="direct-messaging">Direct Messaging</h3>
                  <a href="https://xd.adobe.com/view/047dda2e-0c6d-4685-8aae-4b476f6066be-512e/">
                    View the Direct Messaging Flow
                  </a>
                  <p>
                    Direct messaging would allow users talk to business owners
                    for integral questions such as the vegan-eligibility of a
                    product and feedback. It would also allow for user to user
                    interaction and ways to discuss products.
                  </p>
                  <p>
                    What makes this special to abillion is the ability to add
                    products to collections, which reduces friction to storing
                    it on an external list.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3>Making a Post & Community Page Details</h3>
                  <a href="https://xd.adobe.com/view/bda30e90-feca-44b5-9390-40fbdf76b7e0-e8f0/">
                    View the Community Post Flow
                  </a>
                  <p>
                    Making a post allows users to seek advice and bond with
                    other users under shared interests or locations. Community
                    pages also have a wiki for more resources under the topic.
                  </p>
                  <p>
                    Groups are primarily based off of location, which is hardly
                    seen in current online vegan groups. Smaller spaces would
                    make it easier for meetups and community building.
                  </p>
                </div>
                <div className="col">
                  <img src="abillion/post.gif" alt="direct message" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img src="abillion/search.gif" alt="searching post" />
                </div>
                <div className="col">
                  <h3 id="a-vegan-community-forum">
                    Searching for Posts & Post Details
                  </h3>
                  <a href="https://xd.adobe.com/view/9aafa4df-2043-4658-9226-b5e50af27f3c-cffc/">
                    View the Community Search Flow
                  </a>
                  <p>
                    Searching for posts adds value for users seeking for an
                    answer on the go and might not be meaning to make
                    conversation.
                  </p>
                  <p>
                    Because posts are further sorted by context categories, it
                    adds another layer of organization.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="userresearch">
            <h4 id="user-research">User Research</h4>
            <h2 className="none">Understanding How Vegans Interact Online</h2>
            <p>
              For my user research I analyzed 8 interviews and 39 Typeform
              responses with questions pertaining to how vegans used social
              media and what they prioritized. Although the interviews were
              centered in the US, the survey got responses from London to South
              Africa.
            </p>
            <h3 id="findings">Organizing My Findings</h3>
            <p>
              Below I compiled my key insights into an affinity diagram. After
              analysis, what stood out to me is that the vegan community really
              focuses in on <strong>recommendations</strong> and{" "}
              <strong>advice.</strong>
            </p>
            <Zoom src="abillion/affinitydiagram.png" alt="affinity diagram" />
            <h3 id="key-insights">Key Insights</h3>
            <ul>
              <li>
                Content with high engagement are ones that need{" "}
                <strong>advice</strong> or are about{" "}
                <strong>current events</strong>
              </li>
              <li>
                In the vegan community, sharing is focused on{" "}
                <strong>recipes, articles, and places to visit</strong>
              </li>
              <li>
                For groups it is important that content is{" "}
                <strong>well-organized</strong> and easily{" "}
                <strong>accessible</strong>
              </li>
              <li>
                Social media is important for vegans who usually don&#39;t know
                others who identify as vegan-{" "}
                <strong>location-specific solutions</strong> are a common wish
              </li>
              <li>
                A common misconception for non-vegans is that veganism is
                extreme, and it is a difficult lifestyle
              </li>
            </ul>
            <h3 id="market-research">Market Research</h3>
            <div className="row">
              <div className="col">
                <Zoom src="abillion/yelp.png" />
              </div>
              <div className="col">
                <p>
                  After examining Yelp and TripAdvisor I found that both
                  don&#39;t have heavily integrated social networks like
                  Facebook and Instagram, but they have{" "}
                  <strong>direct messaging</strong> and{" "}
                  <strong>reactions</strong> as features.
                </p>
              </div>
            </div>
            <p>
              In addition after looking at current vegan communities on Facebook
              and Reddit (which has 500k vegan users), I found that those with
              higher <strong>organization</strong> and <strong>post</strong>{" "}
              <strong>quality</strong> had higher participation rates. User
              research and Typeform answers revealed there were types of posts
              that the vegan community often centered on such as recipes and
              articles about activism.
            </p>
          </section>
          <section id="brainstorming">
            <h4>Low-Fidelity Brainstorming</h4>
            <h2 className="none">Exploring Solutions</h2>
            <h3 id="1-group-collaborative-lists">
              1. Group collaborative lists
            </h3>
            <p>
              Group collaborative lists are crowdsourced rankings to popular
              questions such as 'best vegan ice cream products'. This would
              increase user engagement through fulfilling the user need for
              answers to advice.
            </p>
            <img
              src="abillion/Untitled%201.png"
              alt="abillion/Untitled%201.png"
            />
            <h3 id="2-location-based-group-message-boards">
              2. Location based group message boards
            </h3>
            <p>
              A message board that all users in a given area are added to. This
              would connect users based off of location which is important as
              most questions are location-based (for example, where is the best
              food in an area).
            </p>
            <img
              src="abillion/Untitled%202.png"
              alt="abillion/Untitled%202.png"
            />
            <h3 id="3-resident-experts">3. Resident experts</h3>
            <p>
              People who live in a given area and can be an answer source for
              people looking for recommendations (and possibly a friend).
            </p>
            <img
              src="abillion/Untitled%203.png"
              alt="abillion/Untitled%203.png"
            />
            <h4>User Journeys and Personas</h4>
            <h2 className="none">Figuring Out the Product Solution</h2>
            <p>
              To understand and help narrow down which feature to follow, I
              created user journeys demonstrating how different users would use
              the product.
            </p>
            <h3 id="user-personas">User Personas</h3>
            <p>
              Of course users might be more than one persona, but this exercise
              helped me envision how distinct motivations for using the app
              would intersect with the different features I brainstormed.{" "}
            </p>
            <img src="abillion/persons.png" alt="personas" />
            <img
              src="abillion/Untitled%204.png"
              alt="abillion/Untitled%204.png"
            />
            <p>
              In the end we decided on the{" "}
              <strong>Location-based Message Boards</strong>, which renamed as a{" "}
              <strong>Community Forum</strong>. We chose this because it had the
              strongest and most distinct use cases per user-type.
            </p>
            <p>
              Additionally, it would be hard getting enough users to volunteer
              as Resident Experts and it would be hard to help users connect via
              a collaborative list.
            </p>
          </section>
          <section id="infoarch">
            <h4>Information Architecture</h4>
            <h2 className="none">
              Understanding Areas for Product Improvement
            </h2>
            <Zoom
              src="abillion/Untitled%205.png"
              alt="abillion/Untitled%205.png"
            />
            <p>
              Formulating the info architecture was a way to determine the
              intricacies of our community platform, as well as identify ways
              where we could add improvements that distinguish us from existing
              community forums.
            </p>
            <p>
              During this phase I did an audit of similar community forums
              (LinkedIn, Reddit and Facebook) and took inspiration on how they
              created organization. I learned that on Reddit posts can be tagged
              and on Linkedin posts can be created with intent (ex: searching
              for someone to hire, celebrating an occasion).
            </p>
          </section>
          <section id="designdecisions">
            <h2 id="design-decisions">Iterating Based off of User Testing</h2>
            <p>
              Due to time constraints I was only able to run through{" "}
              <strong>one iteration of user testing</strong> and was unable to
              finish with high fidelity mockups. Additionally I focused on{" "}
              <strong>product</strong> ideation opposed to{" "}
              <strong>visual design</strong> ideations and worked within
              abillion&#39;s design system. Below are some pivotal findings.
            </p>
            <h2 id="part-2-creating-communities">
              <em>Part 1: Creating Communities</em>
            </h2>
            <h2 id="feature-1-making-a-post-flow">
              Feature 1: Making a Post Flow
            </h2>
            <p>
              Feedback from user testing is shown below and highlighted in
              orange. I first tested for usability and content design.
            </p>
            <img
              src="abillion/Untitled%2011.png"
              alt="abillion/Untitled%2011.png"
            />
            <h3 id="creating-a-post">Creating a Post</h3>
            <div className="row">
              <div className="col">
                <img
                  src="abillion/Untitled%2012.png"
                  alt="abillion/Untitled%2012.png"
                />
              </div>
              <div className="col">
                <p>
                  Previously for create a post we thought about allowing users
                  to see similar posts to decrease repetitive posts. However,
                  because each user has a specific situation and because
                  abillion wants to increase engagement, this idea was scrapped
                  because it may hinder users from engaging with the platform.
                </p>
                <p>
                  Below in the second iteration, context categories are
                  introduced under create a post (last frame). This solves the
                  user need of needing <em>increased organization</em>.
                </p>
              </div>
            </div>
            <img
              src="abillion/Untitled%2013.png"
              alt="abillion/Untitled%2013.png"
            />
            <p>
              Content-wise users enjoyed the concept of category (especially the
              recipe one). However, refinement is needed as opinion and advice
              seem similar.
            </p>
            <h3 id="final-post-flow">
              Final Flow: Create Post & Community Details
            </h3>
            <p>
              <a href="https://xd.adobe.com/view/bda30e90-feca-44b5-9390-40fbdf76b7e0-e8f0/">
                Interact with the Community Post Flow
              </a>
            </p>
            <img src="abillion/createpostfinal.png" />
            <br />
            <br />
            <h2 id="feature-3-adding-products-to-a-collection">
              Feature 2: Adding Products to a Collection
            </h2>
            <img
              src="abillion/Untitled%2016.png"
              alt="abillion/Untitled%2016.png"
            />
            <p>
              For the final iteration we kept the summarized view but also added
              an option to{" "}
              <strong>
                add to collection directly from the discussion thread
              </strong>
              . For the MVP the summarized view isn&#39;t necessary. Directly
              clicking on the product to add to a collection was more preferred.
            </p>
            <h3>
              Final Flow: Adding to a Collection via Post Through a Summarized
              View
            </h3>
            <Zoom src="abillion/summarizedview.png" />
            <h3>
              Final Flow: Adding to a Collection Directly by Clicking on Product
            </h3>
            <img src="abillion/directclick.png" />
            <h2 id="feature-3-search-flow">Feature 3: Search Flow</h2>
            <h3>Final Flow: Search</h3>
            <img src="abillion/search.png" alt="search" />
            <p>
              For the search flow we decided to prioritize posts as the first
              thing that would show up because users would search it more than
              community.
            </p>
            <h3 id="interactive-prototype-of-the-last-two-flows">
              Interact with Feature 2 and 3 Flows
            </h3>
            <p>
              <a href="https://xd.adobe.com/view/9aafa4df-2043-4658-9226-b5e50af27f3c-cffc/">
                View the Community Search and Adding Products Flow
              </a>
            </p>
            <h2 id="part-1-direct-messaging">
              <em>Part 2: Direct Messaging</em>
            </h2>
            <h3 id="-dm-ing-is-useful-but-it-s-unclear-whether-attaching-a-product-to-a-message-is-">
              <strong>
                DM-ing is useful, but it&#39;s unclear whether attaching a
                product to a message is
              </strong>
            </h3>
            <p>
              I explored two ways for users to attach products to their
              messages:
            </p>
            <img
              src="abillion/Untitled%206.png"
              alt="abillion/Untitled%206.png"
            />
            <p>
              However users said they wouldn&#39;t know a product off the top of
              their head and that creating an accurate search function within
              the chat would be difficult.
            </p>
            <img
              src="abillion/Untitled%207.png"
              alt="abillion/Untitled%207.png"
            />
            <p>
              Users said they would use this option more because they want to
              share things as they <strong>discover them</strong> making option
              2 more important for the MVP.
            </p>
            <h3 id="adding-to-a-collection-from-dm-s">
              Adding to a Collection from DM&#39;s
            </h3>
            <img
              src="abillion/Untitled%208.png"
              alt="abillion/Untitled%208.png"
            />
            <p>
              In the first option, users would add to a collection via a shared
              products tab. However user testing revealed that users did not
              immediately think of looking at shared products.
            </p>
            <img
              src="abillion/Untitled%209.png"
              alt="abillion/Untitled%209.png"
            />
            <p>
              Instead users tapped on the product directly in the chat. User
              testing showed option two was more optimal and used more often.
            </p>
            <p>
              However it is unclear if the feature to add to collection from
              shared items should be cut from the MVP or not as some users said
              it may be useful. More user testing would be needed, but for the
              MVP- <strong>option two should be used at the very least.</strong>
            </p>
            <h3 id="final-direct-messaging-flow">
              Final Flow: DM-ing a Product
            </h3>
            <p>
              <a href="https://xd.adobe.com/view/047dda2e-0c6d-4685-8aae-4b476f6066be-512e/">
                View Live Direct Messaging Adobe XD Prototype
              </a>
            </p>
            <Zoom
              src="abillion/dmproduct.png"
              alt="direct messaging final flow"
            />
            <h3 id="final-direct-messaging-flow">
              Final Flow: Adding to a Collection via Shared Items in Chat
              History
            </h3>
            <Zoom
              src="abillion/chathistory.png"
              alt="direct messaging final flow"
            />
          </section>
          <section id="conclusion">
            <h3 id="differentiation-from-competitors">
              Differentiation from Competitors
            </h3>
            <p>
              Because Reddit has been implemented before, we needed to improve
              upon it with the abillion version. The user need has been added
              above in bold.
            </p>
            <p>
              <strong>
                Users want an easy way to find the information that they want
              </strong>
            </p>
            <ul>
              <li>
                Organization of post type through context categories (opinion,
                recipes, advice etc) - making information searching easier for
                users
              </li>
            </ul>
            <p>
              <strong>Users want a way to organize their information</strong>
            </p>
            <ul>
              <li>
                Posts asking for specific product or restaurant advice or recipe
                advice can be summarized with a collection, which can be added
                to a user&#39;s personal collections
              </li>
            </ul>
            <p>
              <strong>Easy integration with in-app data</strong>
            </p>
            <ul>
              <li>
                Instead of needing to open up to a new tab, users can interact
                with in-app data through referencing it through forums etc.
              </li>
            </ul>
            <p>
              <strong>
                Vegan users are searching for a community where they live
              </strong>
            </p>
            <ul>
              <li>
                abillion offers a smaller, international community in a more
                centralized place, not many competitors in this space
              </li>
              <li>
                Because of the smaller location based community, meetups are
                easier
              </li>
            </ul>
            <h2 id="conclusion">Conclusion</h2>
            <h3 id="looking-forward">Looking Forward</h3>
            <p>
              For the future of abillion, based off of user research I believe
              the community space should develop to optimize abillion as a place
              for vegans to:
            </p>
            <ul>
              <li>
                Ask each other <strong>questions</strong> about products -
                determining if products are ethical and vegan is very important
              </li>
              <li>
                Share <strong>recipes</strong> using products - because vegan
                dishes are perceived as hard to make
              </li>
              <li>
                Find other vegans based off of <strong>location</strong> - users
                typically don't know many other vegans and it can feel isolating
              </li>
            </ul>
            <h3 id="what-i-learned">What I Learned</h3>
            <ul>
              <li>
                <strong>Working with Constraints:</strong> Although I was not
                able to experiment with the UI design on the mid-fidelity
                prototype I was able to garner key insights on different product
                features which will further inform future design decisions.
              </li>
              <li>
                <strong>Communication is Key:</strong> I was remote this summer
                and different time zones meant constantly reaching out to others
                through Slack calls and messages. That meant having really clear
                reasoning for pivotal design decisions.
              </li>
            </ul>
            <h3 id="acknowledgements">Acknowledgements</h3>
            <p>
              Lastly I'd like to thank Dash and Haylie on the design team for
              helping me learn the system and giving me feedback. I'd also like
              to thank Jonathan, my product manager, for holding calls with me
              to go through product decisions. Although my internship was remote
              I really felt like I was a part of the abillion team even though I
              was thousands of miles away.
            </p>
            <p>
              I also completed small design projects such as improving the error
              reporting system and a sustainability quiz. Please contact me for
              more information on these projects!
            </p>
          </section>
        </div>
        <Bottom nextproject="NIH Public Education Site" next="/NIH" />
      </div>

      <Footer />
    </div>
  );
}
export default abillion;
