import React from "react";
import "../App.css";
function ProjHead(props) {
  return (
    <div className="center1">
      {props.image.length !== 0 && (
        <img id="headerimage" src={props.image} alt={props.title} />
      )}
      <div className="projheader">
        <div className="col">
          <h1>{props.title}</h1>
        </div>
        <p>{props.description}</p>
        <div className="four">
          <div className="col">
            <h4>{props.role}</h4>
            <p>{props.team}</p>
          </div>
          {props.context !== "no" && (
            <div className="col">
              <h4>Context</h4>
              <p>{props.context} </p>
            </div>
          )}
          <div className="col">
            <h4>Timeline</h4>
            <p>{props.timeline}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProjHead;
