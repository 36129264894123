import "./App.css";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import React from "react";
import Bottom from "./functions/Bottom.js";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { HashLink } from "react-router-hash-link";

function CANY(props) {
  return (
    <div>
      <div className="bodytext">
        {/* <hr/> */}
        <ProjHead
          title="Correctional Association of New York Data Visualization Dashboard"
          description="Making a more efficient data visualization platform for prison complaints"
          image="./CANY/CANYcover.png"
          timeline="Fall 2020, 6 weeks"
          role="With"
          team="1 Project Manager, 1 Tech Lead, 1 co-designer, 6 Engineers"
          context="Cornell Hack4Impact"
          tools="Figma"
        />
        <div className="sticky">
          <nav className="section-nav">
            <Scrollspy
              items={[
                "problemstatement",
                "solution",
                "infoarch",
                "usertests",
                "designsys",
                "results",
              ]}
              currentClassName="is-current"
            >
              <li>
                <HashLink to="#problemstatement">Problem Statement</HashLink>
              </li>
              <li>
                <HashLink to="#solution">Solution Overview</HashLink>
              </li>
              <li>
                <HashLink to="#infoarch">Info Architecture</HashLink>
              </li>
              <li>
                <HashLink to="#usertests">User Testing</HashLink>
              </li>
              <li>
                <HashLink to="#designsys">Design System</HashLink>
              </li>
              <li>
                <HashLink to="#results">Results</HashLink>
              </li>
            </Scrollspy>
          </nav>
        </div>
        <div className="projmargins">
          <h2>Foreword</h2>
          <p>
            Hack4Impact is{" "}
            <strong> one of my most formative experiences in college</strong>{" "}
            and has made me critically think about the role we hold as
            technologists and how important design is to developing responsibly.
            Beyond this case study, you can check out how I've:{" "}
          </p>
          <ul>
            <li>
              <a href="https://medium.com/hack4impact/reflecting-on-hack4impact-design-as-a-national-director-a5ce7d5dbf63">
                Written
              </a>{" "}
              about the process of creating design resources for all Hack4Impact
              chapters{" "}
            </li>
            <li>
              Grown since my <Link to="/habitat">first project</Link> where I
              had to iterate on a product in two weeks as well as teach proper
              frontend to two of the engineers.
            </li>
            <li>
              Mentored and founded the original design team, view{" "}
              <a href="https://medium.com/cornellh4i">here</a>
            </li>
          </ul>
          <p>
            We were recently recognized for our efforts in improving as an
            organization by winning the{" "}
            <strong>Student Group of the Year</strong> from Cornell Engineering
            during my time as co-director!{" "}
          </p>
          <div className="forty">
            <section id="problemstatement">
              <h2 id="the-problem">The Problem</h2>
              <p>
                There is a huge lack of transparency about conditions inside
                prisons and three supreme court cases (Branzburg v. Hayes, Pell
                v. Procunier, and Saxby v. Washington Post Co.) make it
                difficult for journalists to access these prisons.
              </p>
              <p>
                Currently CANY is the only organization with access to that
                data. CANY wants to surface the data and use it to form an
                argument as to why its important to increase transparency about
                conditions inside prisons.
              </p>
            </section>
            <section id="solution">
              <h2 id="the-solution">The Solution</h2>
              <p>
                Through a dynamic data visualization platform, CANY will able to
                create graphs that use real-time prison complaint data.
              </p>
              <ReactPlayer url="https://youtu.be/Vwp6bs0hroM" />
              <h2 id="competitive-analysis">Competitive Analysis</h2>
              <p>
                First we did market research on similar platforms that involved
                editing custom visuals. What I noticed from each of these is:
              </p>
              <ul>
                <li>
                  Not all options are immediately available, and options are
                  broken off by <strong>distinct aspects</strong> of the graph,
                  artboard, or use-case
                </li>
                <li>
                  Shifting from action to action should require a{" "}
                  <strong>one click</strong> transition
                </li>
                <li>
                  Most of the detailed options are concentrated on the{" "}
                  <strong>sidebar</strong> and rarely on the top bar
                </li>
              </ul>
              <div class="figure">
                <img src="CANY/Untitled.png" alt="CANY/Untitled.png" />
              </div>
            </section>
            <section id="infoarch">
              <h2 id="information-architecture">Information Architecture</h2>
              <p>
                Based off of the initial prompt, we went through several rounds
                of the information architecture. I chose to iterate on
                information architecture first paired with low-fidelity sketches
                because I didn't want to create the mid-fidelity until all the
                details were concretely defined.
              </p>
              <h3 id="iteration-1">Iteration 1</h3>
              <p>
                My initial mental model was that all kinds of graphs with
                different x and y axis's were possible.
              </p>
              <div class="figure">
                <img src="CANY/Untitled%201.png" alt="CANY/Untitled%201.png" />
              </div>
              <div class="figure">
                <img src="CANY/Untitled%202.png" alt="CANY/Untitled%202.png" />
              </div>
              <p>
                However, through talk with the client, I learned that{" "}
                <strong>
                  they were mainly interested in complaints as the dependent
                  variable.
                </strong>
              </p>
              <p>
                From our meeting with the client we also learned they wanted
                features such as a way to save the graph, undo and redo, and
                reset.
              </p>
              <h3 id="iteration-2">Iteration 2</h3>
              <p>
                In the second iteration, complaints were pre-assigned as the y
                axis. I had it so the user could limit the data set from both
                the x and y.
              </p>
              <p>
                In the next meeting with the client, we focused on asking
                questions to define what the client envisioned the y and x axis
                being, and what actions they wanted to take in customizing the
                data.
              </p>
              <div class="figure">
                <img src="CANY/Untitled%203.png" alt="CANY/Untitled%203.png" />
              </div>
              <div class="figure">
                <img src="CANY/Untitled%204.png" alt="CANY/Untitled%204.png" />
              </div>
            </section>
            <section id="usertests">
              <h2 id="user-testing">User Testing</h2>
              <p>
                During our next meeting we tested our prototypes with the
                client, two CANY employees, and two non-CANY affiliated students
                for both <strong>functionality</strong> and{" "}
                <strong>visual design.</strong> As a side note, the flows below
                have been abridged.
              </p>
              <h3 id="visual-feedback">
                <strong>Visual Feedback</strong>
              </h3>
              <div class="figure">
                <img src="CANY/Untitled%205.png" alt="CANY/Untitled%205.png" />
              </div>
              <p>
                We had an array of options visually, but settled on two final
                options to test because they either echoed previous design
                patterns (eg google sheets) or seemed the clearest based off of
                internal team feedback.
              </p>
              <div class="figure">
                <img src="CANY/Untitled%206.png" alt="CANY/Untitled%206.png" />
              </div>
              <p>
                ✓ Nice visually and usable after users played around with
                controls
              </p>
              <p>
                ✗ Unclear at first the correlation between tabs and sidebar
                content, the active color placement in visualization settings
                was unnoticed
              </p>
              <div class="figure">
                <img src="CANY/Untitled%207.png" alt="CANY/Untitled%207.png" />
              </div>
              <p>
                ✓ Able to quickly toggle between different actions, similar to
                Google Sheets UI
              </p>
              <p>
                ✓ Clear relation between the settings summary, edit summary, and
                the task-action
              </p>
              <p>
                ✗ Unclear the settings co-related to the filters above, needs to
                be fixed visually
              </p>
              <p>
                Ultimately, user testing revealed users preferred Option B. We
                decided to freshen up the UI and optimized the spacing. We also
                added more visual hierarchy to the sidebar.
              </p>
              <div class="figure">
                <img src="CANY/Untitled%208.png" alt="CANY/Untitled%208.png" />
              </div>
              <h3 id="function-feedback">
                <strong>Function Feedback</strong>
              </h3>
              <p>
                We tested for the usefulness of our product by asking people at
                CANY to walk us through how they would use our tool to construct
                visualizations they would use from day to day.
              </p>
              <p>
                We discovered that it was hard to distinguish the difference in
                action between defining complaints (third option) and limiting
                the data-set (second option). Both were limiting the data set{" "}
                <em>and</em> defining the x axis at the same time, but they were
                separated as if they were performing two separate actions.
              </p>
              <h3 id="figuring-out-the-3-core-actions">
                <strong>Figuring out the 3 Core Actions</strong>
              </h3>
              <p>
                After talking with the client some more to figure out the three
                core actions, we learned that the y axis would always be the
                number of complaints and that would never change. That meant the
                only axis to be altered would be the <strong>x axis.</strong>{" "}
                The client agreed they still wanted to limit the data set and
                change the graph type - which meant an easy fix would be to
                replace changing <strong>Complaint Type</strong> to{" "}
                <strong>Setting X Axis.</strong>
              </p>
              <h2 id="final-iteration">Final Iteration</h2>
              <p>
                The new information architecture and design reflected the
                changes in the three actions.
              </p>
              <div class="figure">
                <img src="CANY/Untitled%209.png" alt="CANY/Untitled%209.png" />
              </div>
              <p>
                We also realized that the user would need a way to keep track of
                the multiple limits placed under the 'limit data set' option
                which we added visually via a side bar.
              </p>
              <div class="figure">
                <img
                  src="CANY/Untitled%2010.png"
                  alt="CANY/Untitled%2010.png"
                />
              </div>
              <p>
                We also learned that dataset was <strong>incomplete</strong> and
                there would be entries without demographic data. We added the
                explanation via a popup and placed it under the 'limit data set'
                side bar.
              </p>
              <div class="figure">
                <img
                  src="CANY/Untitled%2011.png"
                  alt="CANY/Untitled%2011.png"
                />
              </div>
            </section>

            <section id="designsys">
              <h3 id="design-system">Design System</h3>
              <p>
                The final visual design system was heavily based off of CANY's
                existing brand to maintain consistency, even though it was an
                internal tool. That meant using brand fonts, Oswald and
                Helvetica, as well as brand colors.
              </p>
              <div class="figure">
                <img
                  src="CANY/Untitled%2012.png"
                  alt="CANY/Untitled%2012.png"
                />
              </div>
            </section>

            <section id="results">
              <h2 id="results">Results</h2>
              <p>View a walkthrough the final iteration below!</p>
              <ReactPlayer url="https://youtu.be/Vwp6bs0hroM" />
              <p>
                At our end of semester showcase we demo'd the project to our
                client and we were met with positive reviews. A CANY employee
                expressed his gratitude and said that our tool would make the
                data visualization process much faster and efficient for them,
                and they were so excited to use it.
              </p>
              <p>
                Because the pandemic has been stressful for everyone, our team
                is still working on developing the tool but it will be deployed
                in the spring.
              </p>
              <h3 id="reflection-as-a-designer">Reflection as a Designer</h3>
              <ul>
                <li>
                  <strong>The user knows best</strong> - initially me and my
                  partner had disagreements on visual designs. It wasn't until
                  we left it to the user to give feedback that we were able to
                  make clear, informed decisions on which avenue to pursue.
                </li>
                <li>
                  <strong>Walk through the user journey</strong> - if we had
                  constructed a user journey earlier we would've realized our
                  mental model didn't line up with our client's. We did do it
                  verbally, but a more concrete model would've been more
                  effective.
                </li>
                <li>
                  <strong>Development isn't easy</strong> - a few weeks after
                  hand off we noticed that our designs were not implemented
                  pixel-perfectly. Figuring out the best way to communicate with
                  developers appropriate style sheets is still in progress.
                </li>
              </ul>
              <h3 id="reflection-as-design-lead">Reflection as Design Lead</h3>
              <p>
                This semester it was also my first time as design lead for
                Hack4Impact! Last semester I was the only designer, and we had
                grown to be 4 designers strong. It was a challenge at first
                leading design crits, but I soon found it to be the favorite
                part of my week. We had never met each other in person, but by
                the end of the semester I had received positive reviews that I
                was able to foster a supportive, uplifting environment.
              </p>
              <h2>Bonus</h2>
              <p>
                I also do marketing for Hack4Impact, and recently did a revamp
                of the brand. Check out a graphic I made below!
              </p>
              <img src="habitat/cover.png" img alt="h4i cover" />
              <img src="habitat/facebook.png" img alt="fb cover" />
            </section>
          </div>
        </div>
        <Bottom nextproject="NIH UX Design Work" next="/NIH" />
      </div>

      <Footer />
    </div>
  );
}
export default CANY;
