import "./App.css";
import Bottom from "./functions/Bottom.js";
import Footer from "./functions/Footer.js";
import ProjHead from "./functions/ProjHead.js";
import React from "react";
import Zoom from "./functions/Zoom.js";
import Scrollspy from "react-scrollspy";
import { HashLink } from "react-router-hash-link";

function Twitter(props) {
  return (
    <div>
      <div className="bodytext">
        {/* <hr /> */}
        <ProjHead
          title="Twitter Groups Feature Concept"
          description="Helping users find and nurture their own flocks (groups) on Twitter to allow for more specialized experiences"
          image="twitter/header.png"
          timeline="Summer 2022 Visual Refresh, Spring 2020, 8 weeks"
          role="Role"
          team="Sole Product Designer"
          tools="Figma"
          context="Intro Digital Product Design Course"
        />
        <div className="sticky">
          <nav className="section-nav">
            <Scrollspy
              items={[
                "solution",
                "userresearch",
                "problemstatement",
                "brainstorming",
                "prototyping",
                "conclusion",
              ]}
              currentClassName="is-current"
            >
              <li>
                <HashLink to="#solution">Solution Overview</HashLink>
              </li>
              <li>
                <HashLink to="#userresearch">User Research</HashLink>
              </li>
              <li>
                <HashLink to="#problemstatement">Problem Statement</HashLink>
              </li>
              <li>
                <HashLink to="#brainstorming">Brainstorming</HashLink>
              </li>
              <li>
                <HashLink to="#prototyping">Prototyping</HashLink>
              </li>
              <li>
                <HashLink to="#conclusion">Conclusion</HashLink>
              </li>
            </Scrollspy>
          </nav>
        </div>
        <div className="projmargins">
          <h2>Overview</h2>
          <p>
            While Instagram and Facebook focus on connecting with friends, most
            Twitter users have a mixed audience of friends, internet strangers,
            and sometimes (especially in tech), professionals. Who do you post
            to? Is it a good idea to post about the newest Harry Styles album if
            your audience is mostly professionals?
          </p>
          <h3>
            <em>
              How do we allow users on Twitter who to post what they want
              despite conflicting audiences?
            </em>
          </h3>
          <section id="solution">
            <h4>Solution: Creating Private User-defined Spaces</h4>
            <h2 className="none">
              Introducing Twitter Groups (or perhaps… Flocks)
            </h2>
            <p>
              Users wanted a private space, one that they could define in terms
              of content and visibility. Introducing… Twitter Groups!
            </p>
            <div className="three">
              <div className="col">
                <h3>Search/View </h3>
                <img src="twitter/search.gif" alt="searching groups" />
              </div>
              <div className="col">
                <h3>Browse </h3>
                <img src="twitter/browsegif.gif" alt="browsing groups" />
              </div>
              <div className="col">
                <h3>Create </h3>
                <img src="twitter/create.gif" alt="creating groups" />
              </div>
            </div>
            <p>
              I also revamped the design this year as personal design practice,
              view the older high-fis{" "}
              <a href="https://www.figma.com/file/KYpDAZ0H2ICudcgF10ySTo/Final-Figma?node-id=0%3A1">
                here
              </a>
            </p>
          </section>
          <section id="userresearch">
            <h4>User Research</h4>
            <h2 className="none">The Daily Life of a Tweeter</h2>
            <p>
              I interviewed several students from a variety of ages and career
              paths. For my user interviews, I asked how people browsed, found
              content, and interacted on Twitter. Here’s a summary of the
              insights I found:
            </p>
            <Zoom src="twitter/affinity.png" alt="affinity diagram" />
            <h3>
              In summary, users post on Twitter for <em>multiple people</em> —
              friends, network contacts, and fandoms. They’re constrained by{" "}
              <em>time and audience</em> in their usage.
            </h3>
            <h3>Current Behavior on Twitter</h3>
            <p>
              Users already seemed to want a space of their own. In many
              fandoms, there are “group chats” where users can meet others with
              the same interests in a private space.
            </p>
            <img src="twitter/twitterex.png" alt="twitter examples" />
            <p>
              My user research found that these group chats could get awkward
              and dissolved quickly - a product solution was needed.
            </p>
          </section>
          <section id="problemstatement">
            <h2>The Problem Statement</h2>
            <p>
              When people use Twitter, they find it difficult to be able to
              tweet what they want because:
            </p>
            <div className="three">
              <p>
                <span>🙅‍♀️</span>
                <br /> All their tweets/replies are public and they don’t want
                their entire audience to see them
              </p>
              <p>
                <span>👥</span>
                <br /> They have niche interests where their tweets would only
                apply to a certain group of people
              </p>
              <p>
                <span>👔</span> <br />
                They don’t want to sound unprofessional or uninformed
              </p>
            </div>
            <h3>Understanding Contexts</h3>
            <p>
              Because of Twitter's sprawling user base, I made different use
              cases of the problem statement, showing how different people
              encounter the same problem.
            </p>
            <img src="twitter/usecase.png" alt="usecases" />
          </section>

          <section id="brainstorming">
            <h4>Brainstorming</h4>
            <h2 className="none">How Did I Get There?</h2>
            <p>After brainstorming I landed on three solutions:</p>
            <h3>Twitter Shower Thoughts</h3>
            <img src="twitter/sketch2.jpeg" alt="sketch1" />
            <p>
              ✓ Would allow users to post whatever they wanted to a select
              number of people, almost like a private Twitter integrated into a
              public one.
            </p>
            <p>
              ✗ Would only allow one on one conversations and prevent
              discussion.
            </p>
            <h3>Twitter Private Conversations</h3>
            <img src="twitter/sketch1.jpeg" alt="sketch2" />
            <p>✓ Would allow replies to be only seen to the poster</p>
            <p>
              ✗ Lacked any feasibility for community engagement. After all, we
              tweet because we want to be <strong>heard</strong>.
            </p>
            <h3>Twitter Groups</h3>
            <img src="twitter/sketch3.png" alt="sketch2" />
            <p>
              ✓ Would allow users to post whatever they wanted, but also
              provided the perfect degree of customizability as to who would see
              the post.
            </p>
            <p>
              ✓ The user could use groups to rant to their close friends but
              also post the latest fandom news with fellow internet users at the
              same time.
            </p>
            <p>
              In the end I chose to prototype Twitter Groups, as it best solved
              the user problem.
            </p>
          </section>

          <section id="prototyping">
            <h4>Prototyping</h4>
            <h2 className="none">Figuring Out Where the Pieces Fit</h2>
            <h3>Entry-points</h3>
            <Zoom src="twitter/entrypoint.png" alt="entrypoints" />
            <p>
              Ultimately I decided on option D because user research indicated
              that users were less inclined to use features that were hidden in
              the sidebar (such as Lists). Also, options B and C were ruled out
              because that was how Twitter currently integrated Lists and
              Special News Features into the feed.
            </p>
            <h4>Browsing Groups and Group Details</h4>
            <h2 className="none">How do we view groups?</h2>
            <h3>Groups Central Page</h3>
            <Zoom src="twitter/viewgroups.png" alt="viewgroups" />
            <p>
              I ultimately settled on option D. User feedback indicated that it
              was important to <strong>quickly</strong> and{" "}
              <strong>easily</strong> navigate between each group’s content.
              They didn’t want another cluttered feed like the home page.
            </p>
            <p>
              This posed another problem, how do we still ensure the user can
              view all the content in a single stream? Users indicated they
              still wanted an option to see an <strong>unfiltered view.</strong>
            </p>
            <h3>Integrating an Unfiltered View</h3>
            <Zoom src="twitter/unfilteredview.png" alt="unfilitered" />
            <p>
              Although option A was similar to how Twitter integrated Lists onto
              the homepage, I went with option B in the end because the
              interaction was more seamless while swiping would provide a break
              in the interaction.
            </p>
            <p>
              Also, since the default Twitter feed is organized by highlights,
              the user would implicitly understand that the Twitter groups feed
              is organized the same way.
            </p>
            <h3>Final Interaction for Browsing</h3>
            <div class="special">
              <img src="twitter/browse.gif" alt="browsing groups" />
            </div>
            <h4>Searching Groups</h4>
            <h2 className="none">How do we find these groups?</h2>
            <h3>Iteration 1: Integrating Your Groups, Explore, and Create</h3>
            <img src="twitter/integration.png" alt="integration" />
            <p>
              Initially I had the idea of having{" "}
              <em>Your Groups, Explore and Create</em> all be accessed through
              the same tab system, indicating they had equal importance.
              However, through user research I learned that:
            </p>
            <ul>
              <li>
                Users will only use <em>Explore</em> on Twitter once or twice in
                the beginning when setting up the groups they wanted to follow.
              </li>
              <li>
                Users would use <em>Create</em> once, twice, or even never,
                meaning it didn’t need to be as accessible as viewing the feed.
              </li>
            </ul>
            <h3>Iteration 2: Integrating Explore and Search</h3>
            <Zoom src="twitter/iteration2.png" alt="iteration2" />
            <p>
              For a while, I was stuck on trying to integrate both searching{" "}
              <strong>for all groups on Twitter</strong> (Explore) and searching{" "}
              <strong>within your own groups</strong> (Search). After user
              research, I learned that my explorations in my second iteration
              weren’t enough because:
            </p>
            <ul>
              <li>
                The difference in function between <em>Your Groups, Search,</em>{" "}
                and <em>Explore</em> was <strong>confusing</strong> from first
                glance
              </li>
              <li>
                If users really wanted to look for groups <strong>ALL</strong>{" "}
                over Twitter, they always went to the bottom bar when prompted
              </li>
              <li>
                Users wanted a way to access{" "}
                <strong>a list of groups they were currently in</strong> instead
                of solely relying on the carousel
              </li>
            </ul>
            <h3>Iteration 3: The Final Solution</h3>
            <Zoom src="twitter/iteration3final.png" alt="iteration3" />
            <p>
              For my final solution I simplified <em>exploring</em> and{" "}
              <em>searching</em> into one action.
            </p>
            <ul>
              <li>
                Because users would only have one or two groups under a keyword,
                it allowed space for an option to explore groups from the search
                button on the bottom
              </li>
              <li>
                The empty state would make room for a list of all groups a user
                was in, which made sense because that was when a user would want
                to access a specific group anyways
              </li>
            </ul>
            <h3>What makes these groups so different anyways?</h3>
            <Zoom src="twitter/groupsdifferent.png" alt="groups different" />
            <p>
              User testing showed that leaving the presentation of groups to be
              the same as a user profile proved to be very confusing, ruling out
              option A. Option C would make viewing the follower count difficult
              if the cover photo was a detailed photo.
            </p>
            <p>
              In the end, I chose option B, however there was still a major
              problem-{" "}
              <strong>
                users didn’t know the difference between following and joining
                at first glance.
              </strong>
            </p>
            <h3>What’s the difference between joining and following?</h3>
            <p>
              The point of joining and following was to allow people the option
              of passively following a group and to also allow for more control
              over posting privileges. Only people who joined a group would be
              allowed to post and follow at the same time. This would allow more
              customization in the usage of groups.
            </p>
            <Zoom src="twitter/joinvsfollow.png" alt="join versus follow" />
            <p>
              To explain the difference I took inspiration from how Twitter
              currently explains its features, through a bottom pop-up modal.
            </p>
            <h3>Final Interaction for Searching and Viewing</h3>
            <div class="special">
              <img src="twitter/search.gif" alt="searching groups" />
            </div>
            <h4>Creating Groups</h4>
            <h2 className="none">Forming the Flocks… I Mean Groups</h2>
            <p>
              Although the content requirements for this feature were
              straight-forward, I went through several iterations to make it
              match Twitter’s design system and increase usability.
            </p>
            <Zoom src="twitter/createprocess.png" alt="create process" />
            <p>
              In my original version, it was difficult for the user to know
              where to click to input their information. Also, the invite was a
              scroll within a scroll which would add poor usability.
            </p>
            <p>
              I then split it into three steps and provided context to each so
              the user felt more guided in the group creation process.
            </p>
            <h3>Final Interaction for Creating</h3>
            <div class="special">
              <img src="twitter/create.gif" alt="creating groups" />
            </div>
          </section>
          <section id="conclusion">
            <h2>Changes to Twitter since the Project</h2>
            <p>
              For more context, there were several changes to Twitter since the
              project, such as the <em>Design System</em> and the{" "}
              <em>rise (and fall) of Fleets</em>. I was not able to incorporate
              these specific considerations into the redesign and purely did it
              from the perspective of what the UI was back then.
            </p>
            <p>
              One cool thing is that Twitter actually made a similar feature to
              my idea called <em>Communities</em> a year and a half since the
              project, it's interesting to see what design decisions were shared
              and which weren't!
            </p>
            <img src="twitter/communities.png"></img>
            <h2>Takeaways</h2>
            <div className="row">
              <div className="col">
                <p>
                  <span>🔙</span>
                  <br />
                  <strong>It’s okay to revisit research!</strong> At first I
                  wasn’t sure what the problem was going to be but then after I
                  found that it would have to do with posting I refined my user
                  research questions.
                </p>
              </div>
              <div className="col">
                <p>
                  <span>🔢</span>
                  <br />
                  <strong>Figure out hierarchy of action first</strong> If
                  figured out which features users would use the most then I
                  would have ruled out some visual design iterations earlier.
                </p>
              </div>
            </div>
            <h2>Conclusion</h2>
            <p>
              I didn’t realize it at first, but users don’t need a lot of
              followers to experience this problem. Recently, I started tweeting
              more and I realized that I had professionals following me and
              underclassmen from high school and close friends. I didn’t know
              what to post and so I didn’t post at all.
            </p>
            <p>
              Ultimately, if there were more time I would look into how users
              interact with others within groups. Besides retweets and posts,
              what else would make Twitter Groups different from any old group
              chat or shared account? Would it be possible to integrate
              discussion boards, polls, or collaborations?
            </p>
            <p>
              Finally, if this feature was launched I would{" "}
              <strong>measure success</strong> by noting how many users are
              clicking on the communities tab and if they are spending a
              substantial time scrolling on there. I would also see how many
              users are directly engaging with the platform - seeing if they're
              actively tweeting more or following new users that they interact
              with on Twitter Groups.
            </p>
            <p>
              In the end, Twitter Groups is a solution that at the very least
              makes <strong>Twitter a comfortable space for everyone.</strong>
            </p>
            <h3>
              Read the case study on Medium{" "}
              <a href="https://uxdesign.cc/finding-your-own-nests-a-twitter-case-study-970d2aa4efd8">
                here
              </a>
              !
            </h3>
          </section>
        </div>
        <Bottom nextproject="NYTimes Product Design Internship" next="/nyt" />
      </div>

      <Footer />
    </div>
  );
}
export default Twitter;
