import "./App.css";
import Footer from "./functions/Footer.js";
import Dropdown from "./functions/Dropdown.js";
import React from "react";
import { HashLink } from "react-router-hash-link";
import { HashRouter, Route, Link } from "react-router-dom";

function About(props) {
  return (
    <div>
      {/* <div className="bodytextwide">
            <hr /></div> */}
      <div className="bodytext">
        <div className="row">
          <div className="part1">
            <img src="images/profile2.jpg" alt="Profile Image" />
          </div>
          <div className="part2">
            <h2>❀ Hi, I'm Connie!</h2>
            <em>
              Product designer + technologist from Pennsylvania studying Data
              Science, UX and CS at Cornell. On a mission to design sustainable
              systems to empower the world.
            </em>
            <p>
              Currently, I'm on my second gap with Scale AI ($7B+ evaluation).
              Before, I've wrestled with tools at NYTimes & BuzzFeed and worked
              with early stage startups at the Kleiner Perkins Fellowship. I
              love 0 to 1 design, as it's where I can leverage my skillset in
              design, research, and code (I made this website!).{" "}
            </p>
            <p>
              Beyond work, I enjoy bringing people together to drive change. At
              <a href="https://www.cornellh4i.org/"> Hack4Impact</a>, I founded
              the design team and led{" "}
              <a href="https://medium.com/hack4impact/reflecting-on-hack4impact-design-as-a-national-director-a5ce7d5dbf63">
                national design education
              </a>
              . I also co-founded an open{" "}
              <a href="https://bit.ly/CUxD">UX Design club</a> at Cornell to
              increase access to a design community. For{" "}
              <a href="https://wicc.cornell.edu/#/">
                Women in Computing at Cornell
              </a>
              , I was VP Brand - making CIS more inclusive through media
              campaigns.
            </p>
            <p>
              I'm deeply interested in how tech intersects with creativity and
              public benefit. On the side, I'm researching fact-checking in
              India under{" "}
              <a href="https://www.adityavashistha.com/">
                Professor Vashishtha
              </a>
              . I love curation spaces in all forms having created a{" "}
              <a href="https://showcase.p5js.org">computational art showcase</a>{" "}
              and the layout for{" "}
              <a href="https://reboothq.substack.com/p/back-to-school">
                Kernel (tech)
              </a>{" "}
              magazine. I'm cautious about solutionistism, lately I've wondered{" "}
              <a href="https://dl.acm.org/doi/10.1145/1978942.1979275">
                when to not design technology
              </a>
              .
            </p>
          </div>
        </div>
        <div className="aboutmargins">
          <h2>Beyond the screen... ⚘</h2>
          <div className="left">
            <h3>a list of things I love ↓</h3>
          </div>
        </div>
        <div className="row">
          <div className="part2">
            <p>
              ✎ Doodling for my{" "}
              <a href="https://www.instagram.com/conliuart/">art blog</a>,
              reading and drawing comics. I once had a{" "}
              <a href="https://www.webtoons.com/en/challenge/paper-planes/chapter-one/viewer?title_no=38596&episode_no=2">
                webcomic
              </a>{" "}
              with 18k views.
            </p>
            <p>☀ Giving tours in Philadelphia, my favorite (and home) city</p>

            <p>
              ✉ The poem <a href="https://poets.org/poem/leaves-0">Leaves</a> by
              Lloyd Schwartz and indie music, especially Mitski
            </p>
            <p>
              ★ <a href="https://corny.substack.com/">Writing</a> to grow
              self-awareness and record observations about the world{" "}
            </p>
            <p>
              ☾ Watching Nathan for You and making my friends laugh as an ENFP!
            </p>
            <p>
              ♥ I'm grealy indebted to my design mentors - especially Emily
              Chan, Calvin Zheng, and Femi Badero. Also grateful for everybody
              at <a href="https://wicc.cornell.edu/#/">WICC</a> and{" "}
              <a href="https://voncrammcoop.com/">Redbud Housing Cooperative</a>
              - ya'll have truly changed my Cornell experience.
            </p>
          </div>
          <div className="part1">
            <img src="drawing.jpeg" alt="Original drawing" />
          </div>
        </div>
        <div className="aboutmargins">
          <div className="left">
            <h3>small moments of joy ↓</h3>
            <div className="row">
              <img src="images/pic0.png" />
              <img src="images/pic1.jpeg" />
              <img src="images/pic2.jpeg" />
              <img src="images/pic3.jpeg" />
            </div>
            <h3>
              interested in more? feel free to contact me at cl2264@cornell.edu
            </h3>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
